export const translationObj = {
	en: {
		notifications: {
			NOTIFICATION_LOW_FPS_WARNING: "Low FPS warning",
			NOTIFICATION_INTERFERENCE_WARNING:
				"Bad reading, please try again in better conditions",
			NOTIFICATION_FACE_ORIENT_WARNING: "Please look straight ahead",
			NOTIFICATION_FACE_SIZE_WARNING: "Please move closer to the camera",
		},
		buttonValue: {
			INIT: "Loading",
			START: "Take test",
			STOP: "Stop",
		},
		signalStatus: {
			WEAK_SIGNAL: "Weak signal quality",
			INITIALIZING: "Initializing...",
			MEASURING: "Measuring..",
		},
		"measurementData.bpm": "Heart Rate",
		// 'measurementData.rr': 'Breathing rate',
		"measurementData.rr": "Respiratory rate",
		// 'measurementData.oxygen': 'Blood oxygen',
		"measurementData.oxygen": "Oxygen Saturation",
		"hrvMetrics.sdnn": "HRV SDNN",
		"measurementData.stressStatus": "Stress",
		"measurementData.bloodPressureStatus": "Blood Pressure",
		// 'bloodPressure.systolic': 'Systolic',
		"bloodPressure.systolic": "Blood Pressure",
		"bloodPressure.diastolic": "Diastolic",
		description1: "Please keep your face in the oval",
		description2: "Ensure your face is well lit",
		description3: "Ensure your device is held steady",
		status: "Status",
	},
	fr: {
		notifications: {
			NOTIFICATION_LOW_FPS_WARNING: "fr Low FPS warning",
			NOTIFICATION_INTERFERENCE_WARNING:
				"fr Bad reading, please try again in better conditions",
			NOTIFICATION_FACE_ORIENT_WARNING: "Please look straight ahead",
			NOTIFICATION_FACE_SIZE_WARNING: "Please move closer to the camera",
		},
		buttonValue: {
			INIT: "fr Loading",
			START: "fr Start",
			STOP: "fr Stop",
		},
		signalStatus: {
			WEAK_SIGNAL: "fr Weak signal quality",
			INITIALIZING: "fr Initializing...",
			MEASURING: "fr Measuring..",
		},
		"measurementData.bpm": "Heart Rate",
		"measurementData.rr": "Breathing rate",
		"measurementData.oxygen": "Blood oxygen",
		"hrvMetrics.sdnn": "HRV SDNN",
		"measurementData.stressStatus": "Stress",
		"measurementData.bloodPressureStatus": "Blood Pressure",
		description1: "fr Please keep your face in the oval",
		description2: "fr Ensure your face is well lit",
		description3: "fr Ensure your device is held steady",
		status: "fr Status",
	},
};
